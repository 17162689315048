import React, { useState, useEffect } from 'react';
import './App.css';
import './tailwind.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
// Music Components
import WaveformPlayer from './components/WaveformPlayer';
import BeatSectionEditor from './components/BeatSectionEditor'; // Adjust the path as necessary
// About & Legal Pages
import AboutOurTeam from './Pages/About'; // Adjust the path as necessary
import SubscriptionFeatures from './Pages/SubscriptionFeatures'; // Adjust the path as necessary
import RightsAndUsage from './Pages/RightsAndUsage'; // Adjust the path as necessary
import TermsConditions from './Pages/TermsConditions'; // Adjust the path as necessary
import Banner from './signups/Banner'; // Adjust the path as necessary
//Signup & Set Up Pages
import ProducerForm from './signups/Producer-Signup';
import StripeConnectSuccess from './signups/stripe-connect-success';
import ContributorSetup from './signups/contributor-setup';
import LandingPage from './Pages/landingPage';
import AccountPage from'./Pages/AccountPage';
import SubscriptionOptions from './Pages/SubscriptionOptions'; // Adjust the path as necessary
import SubscriptionSuccess from './Pages/SubscriptionSuccess';
//Various Pages
import DataSet from './Pages/dataSet';
import Licenses from './Pages/licenses';
import AdminPortal from './Pages/AdminPortal';
// Alerts & Notifications
import Notifications from './components/Notifications';
import { displayError, displaySuccess } from './components/utils';
import LearnMoreAccordion from './components/LearnMoreAccordion';
import Layout from './components/ui/Layout';
import RequireAuth from './components/RequireAuth';
// Contributor Content
import SamplesUpload from './Pages/uploadSamples';
import DrumUpload from './Pages/uploadDrums';

function App() {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        isLoading,
        getAccessTokenSilently,
        getIdTokenClaims,
        user
    } = useAuth0();

    const [tokensRemaining, setTokensRemaining] = useState(null);
    const [userSubscriptionLevel, setUserSubscriptionLevel] = useState(null);
    const [beatTitle, setBeatTitle] = useState('');
    const [audioWaveform, setAudioWaveform] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [producerInfo, setProducerInfo] = useState([]);
    const [beatKey, setBeatKey] = useState('');
    const [beatMode, setBeatMode] = useState('');
    const [beatBPM, setBeatBPM] = useState(0);
    const [beatId, setBeatId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [stepMessage, setStepMessage] = useState('');
    //New Loading Overlay State - Feb 19, 2024
    const [showLoadingOverlay, setShowLoadingOvrelay] = useState(false); // New state for loading overlay  
    //New Beat Name State - Feb 28 2024
    const [searchQuery, setSearchQuery] = useState(''); // New state for search query
    const [beatStructureID, setBeatStructureID] = useState(''); // State to show beat structure IDs

    const [errorMessages, setErrorMessages] = useState(''); // Initialize error message state
    const [successMessages, setSuccessMessages] = useState(''); // Initialize success message state
    const [showPurchaseButton, setShowPurchaseButton] = useState(false); // State to control the visibility of the purchase button

    const [placeholder, setPlaceholder] = useState('What type of beat do you want to generate?');
    const [isTyping, setIsTyping] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState('');    
    //Removing the code modal - access code system
    const [userCode, setUserCode] = useState('');

    const [showFullBeatButton, setShowFullBeatButton] = useState(false); //Button For Make Full Beat
    const [showBeatEditor, setShowBeatEditor] = useState(false); // State to control the visibility of beat editor
    const [beatStructure, setBeatStructure] = useState([]); // State to store the beat structure
    const [isStructureLoaded, setIsStructureLoaded] = useState(false);
    const [showEditDropdown, setShowEditDropdown] = useState(false); // State to control dropdown visibility
    const [showEditorOptions, setShowEditorOptions] = useState(true);

//Track if page reloaded --- new code
    const [pageReloaded, setPageReloaded] = useState(false);


    const [editMode, setEditMode] = useState('swap'); // 'swap', 'remove', or 'global'

    // Function to handle edit mode change
    const handleEditModeChange = (mode) => {
        setEditMode(mode);
        setShowBeatEditor(!showBeatEditor); // Toggle visibility based on current state
        setShowBeatEditor(true); // Ensure the beat editor is shown
        setShowEditDropdown(false); // Hide the dropdown menu
        setShowEditorOptions(true); // Show editor options when edit mode is changed
    };
    
    let errorTimeout = null; // Declare a variable to store the timeout

    const toggleDropDown = () => {
        setShowEditDropdown(prevShowEditDropdown => !prevShowEditDropdown);
    };

    const clearError = () => {
        // Get the error message element
        const errorMessageElement = document.querySelector('.errmsg');
        if (errorMessageElement) {
            errorMessageElement.classList.add('errmsg-fadeout'); // Add fade-out class
        }
        // Clear the error message from state after the animation duration
        setTimeout(() => setSubscriptionError(''), 2000); // 2 seconds for fade-out
    };

    //Set the loading state so it can be used across the app
    const setLoadingState = (isLoading) => {
        setLoading(isLoading);
    };
    

    // Function to close the beat editor
    const closeBeatEditor = () => {
        // console.log("Closing Beat Editor"); // Debugging log
        setShowBeatEditor(false);
        setEditMode(null); // Reset edit mode when closing the editor
    };
    
    const placeholders = [
        'What type of beat do you want to generate?',
        'Enter an artist name or genre you\'d like to make',
        'Try trap type beat with country drums',
        'Best results come from \'Artist Name\' Type Beat'
    ];

    useEffect(() => {
        return () => {
            // Cleanup function to clear timeout if component unmounts
            clearTimeout(errorTimeout);
        };
    }, []);
    
    const handleSetSubscriptionError = (message) => {
        setSubscriptionError(message);
        errorTimeout = setTimeout(clearError, 4000); // Clear the error after 6 seconds
    };

    //Update the beat file via change stems
    const handleAudioUrlUpdate = (newAudioUrl) => {
        setAudioWaveform(newAudioUrl);
    };
    
    //Cylce the placeholder
    useEffect(() => {
        const delay = 100; // delay in milliseconds
        const intervalDuration = 5000; // same as CSS animation
    
        const timeout = setTimeout(() => {
            let index = 0;
            const interval = setInterval(() => {
                index = (index + 1) % placeholders.length;
                setPlaceholder(placeholders[index]);
            }, intervalDuration);
    
            return () => clearInterval(interval);
        }, delay);
    
        return () => clearTimeout(timeout);
    }, []);

    //hide the waveform player after logout
    useEffect(() => {
        if (!isAuthenticated) {
            // Reset waveform player and remove current beat
            setShowFullBeatButton(false);
            setShowBeatEditor(false);
            setAudioWaveform(null);
            setBeatTitle('');
        }
    }, [isAuthenticated]);

    //Login/Register Modal
     const handleLoginClick = () => {
             loginWithRedirect();
     };

    
 //Sender Information to the backend
 const sendUserDataToBackend = async (userData) => {
    try {
        const accessToken = await getAccessTokenSilently({
            audience: 'https://s1WgRsk3Z8eZhNrwPnyGiNT3J3p0i0HV-api',
            scope: 'openid email profile'
        });

        const idTokenJson = await getIdTokenClaims();
        const idToken = idTokenJson.__raw;

        const tokenParts = idToken.split('.');
        if (tokenParts.length === 3) {
            // const decodedHeader = JSON.parse(atob(tokenParts[0]));
        } else {
            console.error("Received token is not a JWT, it seems to be a JWE which is encrypted.");
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
            body: JSON.stringify(userData),
        });

        if (response.status === 401) {
            const responseData = await response.json();
            alert(responseData.message);
            if (responseData.redirect) {
                window.location.href = responseData.redirect;
            } else {
                logout();
            }
        } else if (!response.ok) {
            throw new Error('Failed to send user data to backend');
        }

        // const responseData = await response.json();
    } catch (error) {
        console.error('Error sending user data to backend:', error);
        handleLoginClick();
    }
};


//CHange the beat stems by calling the URL update and the beat stems selections
const handleEditBeat = (selectedDrums, newAudioUrl) => {
    handleApplyChanges(selectedDrums);
    handleAudioUrlUpdate(newAudioUrl);
    closeBeatEditor(); 
};

//Refresh the page after login
useEffect(() => {
    if (window.location.search.includes("code=") && !localStorage.getItem('hasRefreshedPostLogin')) {
        setShowLoadingOvrelay(true);  
        localStorage.setItem('hasRefreshedPostLogin', 'true');
      
      setTimeout(() => {
        window.location.reload();
      }, 3000); // 2500 milliseconds = 2.5 seconds
    }
  }, []);


//Updated - fixing the refresh of new user login
//Check for Tokens or Credits remaining
useEffect(() => {
    const fetchUserRelatedData = async () => {
        if (isAuthenticated) {
            const userProfile = {
                email: user.email,
                username: user.username || user.nickname,
            };

            sendUserDataToBackend(userProfile);
            
            try {
                const [subscriptionLevel, tokensData] = await Promise.all([
                    getSubscriptionLevel(),
                    getAccessTokenSilently().then((idToken) =>
                        fetch(`${process.env.REACT_APP_BACKEND_URL}/get_tokens_remaining`, {
                            headers: { Authorization: `Bearer ${idToken}` },
                            credentials: 'include',
                        }).then(response => response.json())
                    ),
                ]);

                // console.log("Subscription Level:", subscriptionLevel);
                setUserSubscriptionLevel(subscriptionLevel);
                setTokensRemaining(tokensData.tokens_remaining);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
            console.log("User is logged in")

        } else {
            // User has logged out, clear user data here
            setTokensRemaining(null);
            setUserSubscriptionLevel(null);
            // Reset other states as needed
            //console.log("User is not logged in")
            localStorage.removeItem('hasRefreshedPostLogin'); // Ensure this is removed when user is not authenticated to reset the condition

        }
        
    };

    fetchUserRelatedData();
}, [isAuthenticated, getAccessTokenSilently, user]);


//Function to fetch the subscription level
const getSubscriptionLevel = async () => {
    try {
        const idToken = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_subscription_level`, {
            headers: { Authorization: `Bearer ${idToken}` },
            credentials: 'include',
        });
        const data = await response.json();
        return data.subscription_level;
    } catch (error) {
        console.error("Error fetching subscription level:", error);
        return 1; // Default to the lowest level in case of an error
    }
};

// Function to open the beat editor
const openBeatEditor = () => {
    if (audioWaveform && beatStructure && beatStructure.length > 0) {
        setShowBeatEditor(true);
        setShowEditorOptions(true);
    } else {
        console.error("Beat structure not loaded yet or no beat generated");
    }
};

// Function to fetch the beat structure
const fetchBeatStructure = async (isFullBeat = false) => {
    try {
        const idToken = await getAccessTokenSilently();
        const endpoint = isFullBeat ? '/get_full_beat_structure' : '/get_beat_structure';
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, {
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error('Failed to fetch beat structure.');
        }
        const data = await response.json();
        // console.log('Fetched beat structure:', data.structure);

        // Split the structure string into an array
        const structureArray = data.structure.split('');
        // console.log('Structure array:', structureArray);

        setBeatStructure(structureArray); // Set beat structure as an array
    } catch (error) {
        console.error('Error fetching beat structure:', error);
    }
};



useEffect(() => {
    if (isAuthenticated) {
        fetchBeatStructure();
    }
}, [isAuthenticated, getAccessTokenSilently]);

// Function to handle the button click for applying changes for beat editing
const handleApplyChanges = (selectedDrums, selectedSection) => {
    setLoadingState(true);

};

// Function to handle the button click for generating beats
const handleGenerateBeat = async () => {
    let eventSource = null;
    let timeoutId = null;
    let progress_id = null;
    
    try {
        if (!isAuthenticated) {
            alert("You must be logged in to generate a beat.");
            handleLoginClick();
            return;
        }
        
        setShowFullBeatButton(false);
        setAudioWaveform(null);
        setLoading(true);
        setProgress(0);
        setStepMessage('Starting beat generation...');

        // Generate a progress ID
        progress_id = crypto.randomUUID();
        
        // Clear any existing progress data first
        try {
            await fetch(`${process.env.REACT_APP_BACKEND_URL}/clear-progress/${progress_id}`, {
                method: 'POST',
                credentials: 'include'
            });
        } catch (error) {
            console.error('Error clearing progress:', error);
        }
        
        // Set up SSE connection first
        console.log('Setting up SSE connection for beat ID:', progress_id);
        const progressPromise = new Promise((resolve, reject) => {
            const progressUrl = `${process.env.REACT_APP_BACKEND_URL}/progress/${progress_id}`;
            console.log('Creating new EventSource for URL:', progressUrl);
            
            let reconnectAttempts = 0;
            const MAX_RECONNECT_ATTEMPTS = 3;
            let isCompleted = false;
            
            const setupEventSource = () => {
                if (eventSource) {
                    eventSource.close();
                }
                
                eventSource = new EventSource(progressUrl, { withCredentials: true });
                
                let lastProgressUpdate = Date.now();
                const TIMEOUT_DURATION = 90000; // 90 seconds timeout
                let checkConnectionInterval;

                const checkConnection = () => {
                    if (isCompleted) {
                        clearInterval(checkConnectionInterval);
                        return;
                    }
                    
                    const now = Date.now();
                    if (now - lastProgressUpdate > TIMEOUT_DURATION) {
                        console.error('Progress updates stalled');
                        clearInterval(checkConnectionInterval);
                        eventSource.close();
                        
                        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS && !isCompleted) {
                            console.log(`Attempting to reconnect (${reconnectAttempts + 1}/${MAX_RECONNECT_ATTEMPTS})`);
                            reconnectAttempts++;
                            setupEventSource();
                        } else {
                            reject(new Error('Progress updates stalled after multiple reconnection attempts'));
                        }
                    }
                };

                checkConnectionInterval = setInterval(checkConnection, 5000);

                eventSource.onmessage = (event) => {
                    console.log('SSE message received:', event.data);
                    lastProgressUpdate = Date.now();
                    
                    try {
                        const data = JSON.parse(event.data);
                        console.log('Parsed progress data:', data);
                        
                        if (data.error) {
                            console.error('Error in progress update:', data.error);
                            clearInterval(checkConnectionInterval);
                            eventSource.close();
                            reject(new Error(data.error));
                            return;
                        }
                        
                        if (data.progress !== undefined) {
                            setProgress(data.progress);
                        }
                        
                        if (data.step) {
                            setStepMessage(data.step);
                        }
                        
                        if (data.status === 'finished' || data.progress === 100) {
                            console.log('Progress complete, closing EventSource');
                            isCompleted = true;
                            clearInterval(checkConnectionInterval);
                            eventSource.close();
                            resolve();
                        }
                    } catch (error) {
                        console.error('Error parsing SSE data:', error);
                        clearInterval(checkConnectionInterval);
                        eventSource.close();
                        reject(error);
                    }
                };

                eventSource.onerror = (error) => {
                    if (isCompleted) return;
                    
                    console.error('SSE connection error:', error);
                    console.error('EventSource readyState:', eventSource.readyState);
                    
                    if (eventSource.readyState === EventSource.CLOSED) {
                        clearInterval(checkConnectionInterval);
                        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS && !isCompleted) {
                            console.log(`Attempting to reconnect (${reconnectAttempts + 1}/${MAX_RECONNECT_ATTEMPTS})`);
                            reconnectAttempts++;
                            setTimeout(setupEventSource, 1000 * reconnectAttempts);
                        } else {
                            reject(new Error('Progress tracking failed after multiple reconnection attempts'));
                        }
                    }
                };

                eventSource.onopen = () => {
                    console.log('SSE connection opened successfully');
                    console.log('EventSource readyState:', eventSource.readyState);
                    lastProgressUpdate = Date.now();
                    reconnectAttempts = 0;
                };
            };
            
            setupEventSource();
        });

        // Create an AbortController for timeout management
        const controller = new AbortController();
        timeoutId = setTimeout(() => controller.abort(), 180000); // 3 minutes timeout

        // Make the POST request with the progress_id
        const response = await retryFetch(`${process.env.REACT_APP_BACKEND_URL}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Progress-ID': progress_id
            },
            body: JSON.stringify({ 
                title: beatTitle,
                progress_id: progress_id 
            }),
            signal: controller.signal
        });

        if (timeoutId) clearTimeout(timeoutId);

        if (!response.ok) {
            if (response.status === 499) {
                throw new Error('Client disconnected');
            }
            const errorData = await response.json();
            throw new Error(errorData.error || 'Server error');
        }

        // Wait for progress to complete
        await progressPromise;

        // Get the final response data
        const responseData = await response.json();
        
        // Extract data from the tuple response
        const [data, status, headers] = responseData;
        
        if (data.error) {
            throw new Error(data.error);
        }

        // Update UI with response data
        setAudioWaveform(data.audio_url);
        setProducerInfo(data.producer_info || []);
        setTokensRemaining(data.tokens_remaining);
        setBeatKey(data.Key);
        setSearchQuery(data.search_query);
        setBeatStructureID(data.beat_structuring);
        setBeatMode(data.Mode);
        setBeatBPM(data.BPM);
        setBeatId(data.beat_id);
        fetchNewTokenCount();
        
        if (data.audio_url) {
            setShowFullBeatButton(true);
            fetchBeatStructure();
        }

    } catch (error) {
        console.error("Error during beat generation:", error);
        handleGenerationError(error);
    } finally {
        if (eventSource) {
            eventSource.close();
        }
        setLoading(false);
        if (progress !== 100) {
            setProgress(0);
        }
        if (timeoutId) clearTimeout(timeoutId);
    }
};

// Add a helper function to handle retries
const retryFetch = async (url, options, maxRetries = 3) => {
    for (let i = 0; i < maxRetries; i++) {
        try {
            const response = await fetch(url, options);
            return response;
        } catch (error) {
            if (i === maxRetries - 1) throw error;
            await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
        }
    }
};

//Regenerate beat from past generations
const handleRegenerateBeat = async (beatId) => {
    setLoading(true);
    try {
        const idToken = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/regenerate_beat/${beatId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${idToken}`,
            },
            credentials: 'include',
        });
        const data = await response.json();
        if (response.ok) {
            setAudioWaveform(data.audio_url);
            setProducerInfo(data.producer_info || []);
            setBeatKey(data.Key);
            setBeatMode(data.Mode);
            setSearchQuery(data.search_query);
            setBeatBPM(data.BPM);
            setBeatId(data.beat_id);
            setShowFullBeatButton(true); // Show the button for further actions
        } else {
            console.error('Error regenerating beat:', data.error);
        }
    } catch (error) {
        console.error('Network error:', error);
    }
    setLoading(false);
};

// Function to handle the button click for making a full beat
const handleMakeFullBeat = async () => {
    setLoading(true);
    try {
        const idToken = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generate_full_beat`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });
        
        // Log the raw response for debugging
        console.log('Full beat response status:', response.status);
        const data = await response.json();
        console.log('Full beat response data:', data);
        
        if (data.error) {
            if (data.details) {
                console.error('Detailed error:', data.details);
                throw new Error(`${data.error}: ${data.details}`);
            }
            throw new Error(data.error);
        }
        
        setAudioWaveform(data.audio_url);
        setProducerInfo(data.producer_info || []);

        if (data.beat_structuring) {
            const structureArray = data.beat_structuring.split('');
            setBeatStructure(structureArray);
            setBeatStructureID(structureArray);
        } else {
            console.error('No beat structure returned');
        }
    } catch (error) {
        console.error('Error generating full beat:', error);
        // Include any error details in the display message
        const errorMessage = error.message || "An error occurred during full beat generation.";
        displayError(errorMessage);
    }
    setLoading(false);
    setShowFullBeatButton(true);
};

// Function to fetch new token count
const fetchNewTokenCount = async () => {
    try {
        const idToken = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_tokens_remaining`, {
            headers: { Authorization: `Bearer ${idToken}` },
            credentials: 'include',
        });
        const data = await response.json();
        setTokensRemaining(data.tokens_remaining);
    } catch (error) {
        console.error("Error fetching tokens remaining:", error);
    }
};

//new changes for loggout
const handleLogout = async () => {
    // Optional: URL of your backend logout endpoint
    const backendLogoutUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/logout`; // Adjust accordingly

    try {
        // Asynchronously call the backend logout endpoint
        const response = await fetch(backendLogoutUrl, {
            method: 'POST', // or 'GET', depending on your backend implementation
            headers: {
                'Content-Type': 'application/json',
                // Include other headers as needed, e.g., Authorization header if required
            },
            // Include credentials if cookies are used for session management
            credentials: 'include', 
        });

        if (!response.ok) {
            throw new Error('Failed to log out from backend.');
        }

        // console.log('Successfully logged out from backend.');
    } catch (error) {
        console.error('Error during backend logout:', error);
    }

    // Clear local storage or session storage
    localStorage.removeItem('userToken');
    localStorage.removeItem('idToken');
    sessionStorage.removeItem('userSession');
    resetUserState();

    // Logout from Auth0
    logout({ returnTo: window.location.origin });
};

// Resetting user-related state upon logout
const resetUserState = () => {
    setTokensRemaining(null);
    setUserSubscriptionLevel(null);
};

const handleGenerationError = (error) => {
    if (error.name === 'AbortError') {
        displayError("Generation timed out. Please try again.");
    } else if (error.message === 'Client disconnected') {
        displayError("Connection was interrupted. Please try again.");
    } else if (error.message.includes('Failed to fetch') || error.message.includes('NetworkError')) {
        displayError("Network error. Please check your connection and try again.");
    } else if (error.message.includes('Insufficient credits')) {
        displayError(error.message);
    } else if (error.message.includes('Progress updates stalled')) {
        displayError("Progress updates stalled. Please try again.");
    } else {
        displayError("An unexpected error occurred. Please try again.");
    }
    
    // Optionally reload after serious errors
    if (error.name === 'AbortError' || error.message === 'Client disconnected') {
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }
};

if (isLoading || showLoadingOverlay){

        return (
            <div className="loading-overlay">
                <div className="loading-spinner"></div>
                 Please wait. Page is Loading...
            </div>
        );

}



    return (
        
        <Router>
        <div className="App">
            <Notifications
            errorMessages={errorMessages}
            successMessages={successMessages}
            setErrorMessages={setErrorMessages}
            setSuccessMessages={setSuccessMessages}
            displayError={displayError}
            displaySuccess={displaySuccess}
        />
            {/* <AlertComponent /> Add the alert component */}



                    {subscriptionError && <div className="errmsg">{subscriptionError}</div>}
                     <Routes>

                     <Route element={<Layout
                                isAuthenticated={isAuthenticated}
                                tokensRemaining={tokensRemaining}
                                showDropdown={showDropdown}
                                setShowDropdown={setShowDropdown}
                                handleLogout={handleLogout}
                                handleLoginClick={handleLoginClick}
                                />}>
                                
                        <Route path="/" element={
                            <RequireAuth>
                            <>
                                {errorMessages && <div className="errmsg">{errorMessages}</div>}
                                {!isAuthenticated && <div className="errmsg">Please log in to use the beat generator.</div>}
                                <MainContent 
                                    placeholder={placeholder}
                                    handleGenerateBeat={handleGenerateBeat}
                                    audioWaveform={audioWaveform}
                                    setBeatTitle={setBeatTitle}
                                    isTyping={isTyping}
                                    setIsTyping={setIsTyping}
                                    loading={loading}
                                    searchQuery={searchQuery}
                                    beatStructureID={beatStructureID}
                                    producerInfo={producerInfo}
                                    beatKey={beatKey}
                                    beatMode={beatMode}
                                    beatBPM={beatBPM}
                                    beatId={beatId}
                                    showFullBeatButton={showFullBeatButton}
                                    handleMakeFullBeat={handleMakeFullBeat}
                                    openBeatEditor={openBeatEditor}
                                    handleEditBeat={handleEditBeat}
                                    showBeatEditor={showBeatEditor}
                                    setShowBeatEditor={setShowBeatEditor}
                                    beatStructure={beatStructure}
                                    handleEditModeChange={handleEditModeChange}
                                    toggleDropDown={toggleDropDown}
                                    showEditDropdown={showEditDropdown}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                    setLoadingState={setLoadingState}
                                    closeBeatEditor={closeBeatEditor}
                                    setShowEditDropdown={setShowEditDropdown}
                                    userSubscriptionLevel={userSubscriptionLevel}
                                    progress={progress}
                                    setProgress={setProgress}
                                    stepMessage={stepMessage}
                                />
                            </>

                            </RequireAuth>
                        } />
                        <Route path="/subscriptions" element={<SubscriptionOptions setSubscriptionError={handleSetSubscriptionError} />} />
                        <Route path="/account/profile" element={<RequireAuth><AccountPage handleRegenerateBeat={handleRegenerateBeat} /> </RequireAuth>} />
                        <Route path="/ysm-licenses" element={<Licenses />} />
                        <Route path="/about" element={<AboutOurTeam />} />
                        <Route path="/subscription-features" element={<SubscriptionFeatures />} />
                        <Route path="/rights-usage" element={<RightsAndUsage />} />
                        <Route path="/terms-conditions" element={<TermsConditions />} />
                        <Route path="/choice" element={<Banner />} />
                        <Route path="/Producer-Signup" element={<RequireAuth> <ProducerForm /></RequireAuth>} />
                        <Route path="/stripe-connect/success" element={<StripeConnectSuccess />} />
                        <Route path="/contributor-setup" element={<RequireAuth><ContributorSetup /></RequireAuth>} />
                        <Route path="/data-set" element={<DataSet />} />
                        <Route path="/upload-samples" element={<RequireAuth> <SamplesUpload /></RequireAuth>} />
                        <Route path="/upload-drums" element={<RequireAuth> <DrumUpload /></RequireAuth>} />
                        <Route path="/success" element={<SubscriptionSuccess />} />
                        <Route path="/admin-portal" element={<RequireAuth> <AdminPortal /></RequireAuth> } />

                   </Route>
                         <Route path='/welcome' element={<LandingPage />} />
                    </Routes>

            </div>
            <LearnMoreAccordion /> {/* Add this line where you want the accordion to appear */}

            </Router>
        
        );
                    }

                    
function MainContent({ 
    placeholder, 
    handleGenerateBeat, 
    audioWaveform, 
    setBeatTitle, 
    isTyping, 
    setIsTyping, 
    loading, 
    producerInfo, 
    showFullBeatButton, 
    handleMakeFullBeat,
    showBeatEditor, 
    beatStructure, 
    handleEditBeat, 
    editMode, 
    handleEditModeChange, 
    toggleDropDown, 
    showEditDropdown,  
    setShowEditDropdown, 
    setLoadingState, 
    setShowEditorOptions, 
    closeBeatEditor, 
    beatBPM, 
    beatMode, 
    beatKey, 
    userSubscriptionLevel, 
    searchQuery, 
    beatStructureID, 
    beatId,
    progress,
    setProgress,
    stepMessage
}) 
{ 
    return (
        <main>
            <div className="search-container">
                <div className="placeholder-container"> 
                {!isTyping && (
                     <div className="placeholder-text" style={{ animationName: 'slideCube' }}>
                     {placeholder} 
                 </div>
                    )}
                </div>
                <textarea
                    className="search-input"
                    rows="1" // Initial number of rows
                    onChange={e => {
                        setBeatTitle(e.target.value);
                        if (e.target.value) {
                        setIsTyping(true);
                        } else {
                        setIsTyping(false);
                        }
                        // Adjust the textarea height based on content
                        e.target.style.height = 'auto'; // Reset height
                        e.target.style.height = e.target.scrollHeight + 'px'; // Set to scrollHeight
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter' && !loading) {
                        e.preventDefault(); // Prevent default behavior of Enter key
                        handleGenerateBeat();
                        }
                    }}
                    />
                <div className='button'>
                    <button className="btn bg-sky-400 hover:bg-blue-600" onClick={handleGenerateBeat} disabled={loading}>Generate</button>
                </div>
                
                </div>
                {loading && (
                    <div className="loading-container">
                        <div className="loading-animation" />
                        <div className="loading-content">
                            <div className="loading-status">
                                Loading... Please wait while we generate your beat.
                            </div>
                            <div className="progress-container">
                                <div className="progress-bar-container">
                                    <div 
                                        className="progress-bar" 
                                        style={{ width: `${progress}%` }}
                                    />
                                    <div className="progress-text">{progress}%</div>
                                </div>
                                {stepMessage && (
                                    <div className="progress-step-message">
                                        <div className="step-message">
                                            {stepMessage}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                        
        {!loading && audioWaveform && (
            <div className="Audio-Player">
                <WaveformPlayer 
                beat_id={beatId}
                audio_url={audioWaveform}
                //New Feature Search Query Feb 28, 2024
                beatTitle={searchQuery}
                beatStructureLabels={beatStructureID}
                //////////////
                Beatkey={beatKey}
                mode={beatMode}
                bpm={beatBPM} />
                <div className="producer-info">
                <div><strong>Contributed Producers:</strong></div>
                <br/>
                    {producerInfo.map((info, index) => (
                        <div key={index}>
                            <strong>{info.producer}</strong>: {info.percentage}
                        </div>
                    ))}
                </div>
            </div>
        )}
        {audioWaveform && showFullBeatButton && (
            <button 
            onClick={handleMakeFullBeat} 
            disabled={loading || userSubscriptionLevel < 2} 
            className="generate-full-beat-button" // Unique class for styling
        >
            Make Full Beat
        </button>
        
        )}
        {audioWaveform && (
                <div>
                <button onClick={toggleDropDown} 
                disabled={ userSubscriptionLevel <2}
                className="generate-full-beat-button">
                    Edit Beat Sections
                </button>
                
                {showEditDropdown && (
           <div className="dropdown-menu" style={{ display: 'block' }}>
                <button onClick={() => handleEditModeChange('swap')} 
                disabled={ userSubscriptionLevel <2}
                className="dropdown-item">
                    Change Drums
                </button>
                <button onClick={() => handleEditModeChange('remove')} 
                disabled={ userSubscriptionLevel <2}
                className="dropdown-item">
                    Remove Drums
                </button>
                <button onClick={() => handleEditModeChange('global')} 
                disabled={ userSubscriptionLevel <2}
                className="dropdown-item">
                    Set Global Drums
                </button>
       </div>
   )}
</div>
        )}
            {showBeatEditor && (
                <BeatSectionEditor
                onClose={closeBeatEditor}
                onApplyChanges={handleEditBeat}
                beatStructure={beatStructure}
                editMode={editMode}
                setLoadingState={setLoadingState}
                loading={loading}
                showEditorOptions={showEditDropdown}
                setShowEditorOptions={setShowEditorOptions}
                onApplyStart={() => setShowEditDropdown(false)}
                />
            )}
            
     </main>
     
                );
            }
    
export default App;
